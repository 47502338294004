import Layout from '@/component/layout'
import React, { Component } from 'react'
import { Poppins } from 'next/font/google'
import Link from 'next/link'
const poppins=Poppins({weight:"400",subsets:['latin']})
export class Error extends Component {
  render() {
    return (
      <>
     <Layout>
      <section className='not-found-bg '>
        <div className='df fjc fafe'>
     <Link href={"/"} >
     <button className={`not-found-btn cb fs-24 mfs16 ${poppins.className}`}>Go to Home</button>
     </Link>
        </div>
        
      </section>
      </Layout>
      
  <style jsx>
    
    {`
    .not-found-bg{
      background-image: url('/404-img/not-found.webp');
      width:100%;
      height: 100vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
     
      background-size: 100% 100%;
      z-index:-1000px;
    }
    .not-found-btn{
      background-color: #fff;
      border-radius:15px;
      border: none;
      padding:8px 15px 8px 15px;
      cursor: pointer;
      position:absolute;
      bottom:50px;
      left:46%;
    }    
    .not-found-btn:hover{
      background-color: #000;
      color:#fff;
    }
    @media only screen and (max-width:1023px){
      .not-found-btn{
        background-color: #fff;
        border-radius:5px;
        border: none;
        padding:5px 8px;
        cursor: pointer;
        position:static;
        margin-top:330%;
      }  
    }
    `}
    </style>  
      
      </>
    )
  }
}

export default Error